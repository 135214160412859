import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTooth, faPhone } from '@fortawesome/free-solid-svg-icons'
import Subscription from '../components/subscription-cta'
import Bullseye from '../components/Bullseye'

import '../styles/subscription.scss'
import '../styles/main.scss'

const FindDentistPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title="DDSLocator Patient Leads | Dental Patient Marketing and Advertising"
          description="DDSLocator delivers patient leads to dental practices using a unique mix of dental advertising and marketing strategies. Acquire more patients and increase revenue for your dental practice."
        />
        <Container fluid={true}>
          <Row>
            <Col md="12">
              <div className="main-image mt-4">
                <StaticImage
                  src="../images/DDSLocator-header-2500x651.jpg"
                  alt="Dentists"
                  placeholder="none"
                  layout="fullWidth"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid={true}>
          <Row>
            <Col md="12">
              <h2 className="mb-3 mt-5 text-center title">
                Find a Dentist with DDSLocator
              </h2>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">1. Find a Dentist</h2>
              <p className="text-center">Search for dentists in your area</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faSearch} size="5x" />
              </div>
            </Col>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">2. Choose a Dentist</h2>
              <p className="text-center">View profile, services and reviews</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faTooth} size="5x" />
              </div>
            </Col>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">3. Contact a Dentist</h2>
              <p className="text-center">Complete form to contact dentist</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faPhone} size="5x" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mt-5">
            <Col md="12">
              <Bullseye site="DenMat-SideMap" />
              <div className="text-center">
                <em>
                  *DDSLocator requires cookies to be active in order to display
                  location services.
                </em>
              </div>
            </Col>
          </Row>
        </Container>
        <Subscription></Subscription>
      </Layout>
    </>
  )
}

export default FindDentistPage
