import React, { useState } from 'react'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

const getBullseyeURL = site => {
  let searchParams = window.location.search
  let parentURL = window.location.href.split('?')[0]
  if (searchParams && searchParams.includes('?')) {
    if (!searchParams.includes('f=1')) searchParams += '&f=1'
    if (!searchParams.includes('urlR=')) searchParams += '&urlR=' + parentURL
  } else {
    searchParams = '?f=1&urlR=' + parentURL
  }

  let url = `https://www.bullseyelocations.com/pages/${site}` + searchParams
  return { url, searchParams, parentURL }
}

const bullseyelistener = e => {
  var regex = new RegExp('^.*bullseyelocations.com.*$', 'i')
  if (regex.test(e.origin)) {
    if (Number.isInteger(e.data)) {
      document.getElementById('bullseye_iframe').height = Math.round(e.data)
    } else {
      if (e.data === 'scroll') {
        window.scrollTo(0, 0)
      }
    }
  }
}

const Bullseye = ({ site }) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    setUrl(getBullseyeURL(site).url)
    if (window.addEventListener) {
      window.addEventListener('message', bullseyelistener, false)
    } else {
      window.attachEvent('onmessage', bullseyelistener)
    }
    return () => {
      if (window.addEventListener) {
        window.removeEventListener('message', bullseyelistener)
      } else {
        window.detachEvent('onmessage', bullseyelistener)
      }
    }
  }, [site])

  return (
    <>
      {url && (
        <iframe
          id="bullseye_iframe"
          title="Bullseye Locations Search"
          allowFullScreen={true}
          src={url}
          width="100%"
          height="600"
          border="0"
          style={{ border: `none` }}
          scrolling="auto"
          allow="geolocation"
        ></iframe>
      )}
    </>
  )
}

Bullseye.propTypes = {
  site: PropTypes.oneOf(['DenMat-SideMap', 'SnaponSmile', 'Lumineers'])
    .isRequired
}

export default Bullseye
