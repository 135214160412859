import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import '../styles/subscription.scss'
import { Link } from 'gatsby'

const SubscriptionCta = () => {
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col md="12">
            <div className="text-center">
              <h1 className="mt-5 mb-5 text-center">
                Are you a dental professional?
              </h1>
              <button className="button-container text-center">
                <Link to="/dentist">Dentist</Link>
              </button>
              <button className="button-container text-center">
                <Link to="/group-dso">DSO | Group</Link>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SubscriptionCta
